import { Route } from '@angular/router';
import { PageNotFoundComponent } from '@shared/feature/common';
import { KBLoginComponent } from './kb-login/kb-login.component';

export const ROUTES: Route[] = [
  { path: '', redirectTo: 'portal', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('@shared/feature/auth').then((m) => m.ROUTES),
    data: { title: 'Bibliotheekmonitor', headerColor: 'accent', otherProviderKB: true, otherProvider: KBLoginComponent },
  },
  { path: 'portal', loadChildren: () => import('./portal/routes').then((m) => m.ROUTES) },
  { path: 'print', loadChildren: () => import('./print/routes').then((m) => m.ROUTES) },
  { path: '**', component: PageNotFoundComponent },
] satisfies Route[];
