import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { authConfig } from '@shared/feature/auth';
import { provideDataAdapter } from '@shared/util/date-adapter';
import { infrastructureConfig } from '@shared/util/infrastructure';
import { materialConfig } from '@shared/util/material';
import { translationsConfig, TranslocoHttpLoader } from '@shared/util/translations';
import { QRCodeModule } from 'angularx-qrcode';
import { AppComponent } from './app/app.component';
import { RoleNl } from './app/core/bm-user-roles';
import { ROUTES } from './app/routes';
import { environment } from './environments/environment';

const activeLanguage = 'nl-NL';
registerLocaleData(localeNl, activeLanguage);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    authConfig,
    importProvidersFrom(BrowserModule, QRCodeModule),
    infrastructureConfig(environment.apiUrl),
    provideHttpClient(),
    provideDataAdapter(activeLanguage),
    translationsConfig(TranslocoHttpLoader.getTransLocoConfig(environment.production, activeLanguage), RoleNl),
    provideAnimations(),
    materialConfig(),
    provideRouter(ROUTES, withRouterConfig({ paramsInheritanceStrategy: 'always' })),

    { provide: LOCALE_ID, useValue: activeLanguage },
  ],
}).catch((err) => console.error(err));
