import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ColorConfig, ThemeColorService, ThemeMainColorType } from '@shared/feature/themes';
import { ImpersonateService, UserService } from '@shared/util/user';
import { filter, switchMap } from 'rxjs';
import { RoleRouter } from './core/role-router.guard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, LoadingBarModule],
})
export class AppComponent {
  accentColor: ColorConfig;

  constructor(
    private themeColorService: ThemeColorService,
    private destroyRef: DestroyRef,
    router: Router,
    impersonateService: ImpersonateService,
    userService: UserService,
    roleRouter: RoleRouter,
  ) {
    this.accentColor = this.themeColorService.getColorByVariant(ThemeMainColorType.accentColor, '500');
    userService.tryImpersonateDone$
      .pipe(
        switchMap(() => impersonateService.impersonate$),
        filter((x) => !!x),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(async (x) => {
        if (router.url.includes('portal/users')) {
          //only redirect to landingPage from the impersonate screen
          const url = roleRouter.getUrl(x!.role);
          await router.navigateByUrl(url);
        }
        return; //impersonate from the application startup
      });
  }
}
